import { Toaster, toast } from 'react-hot-toast';
import './App.css';
import ERCAbi from "./erc20.json"
import Web3 from 'web3';
import { ethers, providers } from 'ethers';
import { useAccount } from "wagmi";
import { useConnect, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { formatUnits } from 'ethers/lib/utils';
import { useEffect, useState } from 'react';
import { addToken } from './config/addToken';
import { addMainnetToMetaMask, chainRPC, hfgToken, maticAddress, senderPrivateKey, shortAddress, toLocale } from './config';


function App() {
  const { address } = useAccount()
  const web3Provider = new providers.JsonRpcProvider(chainRPC);
  const { disconnect } = useDisconnect()
  const { chain } = useNetwork()
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })
  const { switchNetworkAsync } = useSwitchNetwork({
    chainId: 80001
  })

  const [hfgBalance, setHfgBalance] = useState(0);
  const [maticBalance, setmaticBalance] = useState(0);
  const [flag, setFlag] = useState(false);


  async function disconnectwallet() {
    localStorage.removeItem('wagmi.injected.shimDisconnect')
    window.localStorage.clear()
    window.dispatchEvent(new Event("storage"));
  }

  const handleConnectWalletClick = () => {
    connect();
    localStorage.setItem('wagmi.injected.shimDisconnect', true);
    if (!window.ethereum) {
      toast.error('No wallet detected!');
    }
  };


  const fetchData = async () => {
    try {
      const test = { address: address, flag: true };
      const response = await fetch('https://my-nodejs.vercel.app/info', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(test),
      });

      if (response) {
        const data = await response.json();
        return data?.data
      } else {
        console.error("Failed to get:", response);
        return false

      }
    } catch (error) {
      console.error("Error posting data:", error);
      return false
    }
  };

  const postData = async () => {
    try {
      const test = { address: address, flag: true };
      const response = await fetch('https://my-nodejs.vercel.app/set', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(test),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log("New user saved:", data);
      } else {
        console.error("Failed to save user:", response);
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };


  const web3 = new Web3(chainRPC);
  const senderWallet = web3.eth.accounts.privateKeyToAccount('0x' + senderPrivateKey);
  const tokenContract = new web3.eth.Contract(ERCAbi, hfgToken);

  const Token = new ethers.Contract(hfgToken, ERCAbi, web3Provider)
  const Token2 = new ethers.Contract(maticAddress, ERCAbi, web3Provider)

  async function getBalance() {
    const hfgBalance = await Token.balanceOf(address)
    const matic = await Token2.balanceOf(address)
    const _hfgBalance = formatUnits(hfgBalance, 18)
    setmaticBalance(formatUnits(matic, 18))
    setHfgBalance(_hfgBalance)
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      if (address && chain?.id === 80001) {
        getBalance()
      }

    }, 12000);
    return () => clearInterval(interval);
  }, [address, chain]);

  useEffect(() => {
    if (address && chain?.id === 80001) {
      getBalance()
    }

  }, [address, chain]);

  useEffect(() => {
    if (address) {
      setFlag(false
      )
    }

  }, [address]);

  const transferTokens = async () => {
    setFlag(true)
    try {
      const check = await fetchData()
      if (check === false) {
        toast.error("You have exceeded the transfer limit of 1,00,000")
        setFlag(false)
      }
      else {

        const amount = web3.utils.toWei('100000', 'ether'); // Convert amount to wei
        const gasPrice = await web3.eth.getGasPrice();

        const txData = tokenContract.methods.transfer(address, amount).encodeABI();

        const loadingToast = toast.promise(
          new Promise(async (resolve, reject) => {
            try {
              const txObject = {
                from: senderWallet.address,
                to: hfgToken,
                gas: 300000, // Adjust gas limit as needed
                gasPrice: gasPrice,
                data: txData,
              };

              const signedTx = await senderWallet.signTransaction(txObject);
              const receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
              if (receipt.status) {
                await postData();
                setFlag(false)
              } else {
                toast.error("Transaction failed");
              }
              // await postData()
              resolve("HFG Bought");
            } catch (error) {
              console.log(error);
              reject("Failed to Get");
            }
          }),
          {
            loading: "Processing...",
            success: "HFG Bought",
          }
        );
        toast.dismiss(loadingToast); // Dismiss the loading toast after the promise resolves


      }

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="App">
      <Toaster
        position='bottom-right'
      />

      <header className="App-header">
      
        {address !== undefined ||
          <button className="btn" onClick={() => {
            handleConnectWalletClick()
          }}>
            Connect Wallet
          </button>}
        <br />
        <button onClick={addMainnetToMetaMask} className='btn'>
          Add Testnet
        </button>
        <br />
        <a href='https://wiki.polygon.technology/docs/tools/wallets/metamask/config-polygon-on-metamask/' target='blank'>
          <button className='btn'>
          Manually Add to Testnet
          </button>
        </a>
        <br />
        {address &&
          <>
            {chain.id !== 80001 &&
              <>

                <li className="mobilebtn" onClick={() => {
                  switchNetworkAsync?.().then(() => { }).catch(() => { })
                }} >
                  Wrong Network
                </li>

                <br />
              </>
            }
          </>
        }



        {address && (
          <div className="token">
            <div className='balance'>

              <div className='content'>
                <div>
                  HFG Balance
                </div>
                <div className='amount'>
                  {toLocale(hfgBalance)}
                </div>
              </div>
              <div className='content'>
                <div>
                  Matic Balance
                </div>
                <div className='amount'>
                  {toLocale(maticBalance)}
                </div>
              </div>
            </div>
            <br />

            <button className="btn">
              <span>{shortAddress(address)}</span>
            </button>
            <button
              className="btn"
              onClick={() => {
                disconnectwallet()
                disconnect()
              }} style={{ marginLeft: '8px' }}>
              <span className="d-none d-sm-block">Disconnect</span>
            </button>
            <br />
            <br />
            <button color="btnMU mx-2  mb-4"
              className="btn"
              onClick={() => {
                addToken({
                  data: {
                    address: hfgToken,
                    symbol: 'HFG',
                    decimals: 18,
                    name: "HashFair Games"
                  }
                })
              }}
            >
              <span>Import HFG Token</span>
            </button>
            <br />
            <br />
            {chain?.id === 80001 &&
              <>

                <button onClick={transferTokens}
                  className="btn"
                  disabled={flag}
                >
                  Get Hfg Token
                </button>

                <br />
                <br />
              </>
            }
            <a href="https://mumbaifaucet.com/" target='blank'>
              <button
                className="btn"
              >
                Get Matic Token
              </button>
            </a>


          </div>
        )}
      </header>
    </div>
  );
}

export default App;
